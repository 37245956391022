img {
    max-width: 100%;
    height: auto;
}

.about-image-section {
    background: linear-gradient(180deg, #1f252f, #15191ff2 49.48%, #232c3a);
    padding: 5rem 0rem 3rem;

}

.about-image-section .title {
    position: relative;
    display: block;
    font-size: clamp(55px, calc(85*(100vw / 1440)), 85px);
    line-height: 1.1;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0px;
    margin-bottom: 10px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #e1e1e1d4;
    background: transparent;
    color: transparent;
    margin-bottom: 3rem;
    text-align: center;
}

.about-sec-title h2 {
    position: relative;
    display: block;
    padding-bottom: 18px;
    font-size: clamp(25px, calc(35*(100vw / 1440)), 35px);
    line-height: inherit;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #fff;
    margin-bottom: 1.5rem;
}

.about-sec-title h2:before {
    position: absolute;
    content: '';
    left: 0px;
    bottom: 0px;
    width: 50px;
    height: 3px;
    background-color: #d1d2d6;
}

.about-image-section .content-column {
    position: relative;
    margin-bottom: 50px;
}

.about-image-section .content-column .inner-column {
    position: relative;
    padding-left: 2rem;
}

.about-image-section .text {
    font-size: 16px;
    margin-bottom: 1em;
    font-style: unset;
    color: #fff;
}



.about-image-section .btn-box {
    position: relative;
    margin: 3rem auto;
}

.about-image-section .btn-box a {
    color: #2E2E2E;
    font-weight: 600;
    border-radius: 30px;
    padding: 18px 47px;
    background: linear-gradient(90deg, #FFE259 15.1%, #FFA751 85.42%);
    border: none;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: .3px;
    font-size: 16px;
    text-align: center;
}

.about-image-section .btn-box a:hover {
    background: #fff;
    box-shadow: 0px 2px 20px 10px #97B1BF;
    color: #000;
}



.about-image-section .image-column .image-1 {
    position: relative;
}



.about-image-section .image-column .image-1 img {
    box-shadow: 0 30px 50px rgba(8, 13, 62, .15);
    border-radius: 46px;
}

@media screen and (max-width:999px) {
    .about-image-section .content-column .inner-column {
        margin-top: 1rem;
        padding-left: 0;

    }

    .about-image-section {
        padding:0;
        padding-top: 3rem;
    }

}