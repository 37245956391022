.record-breaking-text{
  font-size: 45px;
}
.card-sub-title-proposal-section{
  font-size: 16px;
}
.card-sub-title-2-proposal-section{
  font-size: 14px;
}

  .main-card-container{
    /* height:100vh; */
   
    background:linear-gradient(180deg,#1f252f,#15191ff2 49.48%,#232c3a);
    text-transform:uppercase;
    padding: 3rem 1.5rem;
  }
  .card-wrapper-container{
    display:flex;
    place-items:center;
  }
  .main-card-container h2 {
    font-size: clamp(55px, calc(85*(100vw / 1440)),85px);
    line-height: 1.1;
    font-weight: 700;
     text-transform: uppercase;
    letter-spacing: 0px;
    margin-bottom: 10px;
    text-align: center;
    -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #e1e1e1d4;
  background: transparent;
  color: transparent;
   
  }
  .main-card-container .main-heading {
    /* font-size: clamp(25px, calc(35*(100vw / 1440)),35px); */
    line-height: inherit;
    /* font-weight: 700; */
     text-transform: uppercase;
    letter-spacing: 0px;
    margin: 2.5rem auto;
    text-align: center;
   color:#fff;
   text-decoration: underline;
   text-underline-offset: 10px;
   text-decoration-thickness: 4px;
   
   
  }
 .card-wrapper-container .card1{
    position:relative;
    max-width:600px;
    width:100%;
    height:400px;
    background-image:linear-gradient(180deg,#1f252f,#15191ff2 49.48%,#232c3a);
    background-size:cover;
    background-position:center center;
    border-radius:calc( 0.15 * 400px);
    transition:all .2s ease-in-out;
    display:grid;
    place-items:center;
    color:#fff;
    /* font-weight:bolder;
    font-size:2rem; */
    margin:.5em;
    box-shadow: inset -20px -20px 25px rgba(5, 5, 5, 1);
    display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(7, 7, 7, 0.65);
  background-blend-mode: multiply;
    
        
  }
  .card-wrapper-container .swiper-wrapper{
    margin-top:1rem;
    margin-bottom:4rem;
}
  .card-wrapper-container .swiper-pagination .swiper-pagination-bullet-active::after {
    content: "";
    background: #ffffff;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .card-wrapper-container .swiper-pagination .swiper-pagination-bullet-active {
    border: 1px solid #fff;
    background-image: none !important;
    width: 20px !important;
    height: 20px !important;
    padding: 0;
    position: relative;
    background-color: #0000 !important;
    outline: unset !important;
  }
  .card-wrapper-container .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: #ababab5e !important;
    margin: 0 10px 0 0 !important;
    opacity: 1;
  }
  .card-wrapper-container .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  .card-wrapper-container .card1 p{
    /* font-size: 16px; */
    margin-bottom: 1em;
    padding: 2rem !important;
    text-align: center;
  }
  .card-wrapper-container .card1 h3{
    color: #fff;
  text-align: center;
  /* font-size: 20px; */
  text-transform: uppercase;
  /* font-weight: bolder; */
  letter-spacing: 1.3px;
  margin-bottom: 0;
  }
  .card-wrapper-container .card-button-wrapper{
    position:absolute;
    bottom:0;
    right:0;
    /* width:calc( 0.3 * 400px);
    height:calc( 0.3 * 400px); */
    background:linear-gradient(180deg,#1f252f,#15191ff2 49.48%,#232c3a);
    border-radius: calc( 0.2 * 400px) 0 0 0;
    padding:calc( 0.064 * 400px) 0 0 calc( 0.064 * 400px);
    
  }
  .card-wrapper-container .card-button{
    display:grid;
    place-items:center;
    width:calc( 0.3 * 400px);
    height:calc( 0.3 * 400px);
    border-radius:50%;
    background:#fff;
    
    bottom:0;
    right:0;
    font-size:calc( 0.03 * 400px);
    transition:all .2s ease-in-out;
    text-align: center;
  padding: 1.5rem;
 
  }
  .card-wrapper-container .card-button a{
    font-weight: bold;
    color:#000;
  }
  
  .card-wrapper-container .curve{
     
     width:calc( 0.15 * 400px);
    height:calc( 0.15 * 400px);
    background: radial-gradient(circle, 180deg,#1f252f,#15191ff2 49.48%,#232c3a);
    background-position:calc( -0.15 * 400px) calc( -0.15 * 400px);
    background-repeat:repeat;
    background-size:200% 200%;
    
    
  }
 .card-wrapper-container .crv-right{
   position:absolute;
    bottom:calc( 0.361 * 400px);
    right:-1px;
  }
 .card-wrapper-container .crv-bottom{
   position:absolute;
    bottom:-1px;
    right:calc( 0.361 * 400px);
  }
  
 .card-wrapper-container .card-button:hover{
      transform:rotate(-30deg);
    }  
  .card-wrapper-container .card-button:hover{
    display:grid;
    place-items:center;
    width:calc( 0.3 * 400px);
    height:calc( 0.3 * 400px);
    border-radius:50%;
    background:linear-gradient(90deg, #FFE259 15.1%, #FFA751 85.42%);
    color:#000;
    bottom:0;
    right:0;
    font-size:calc( 0.03 * 400px);
    transition:all .2s ease-in-out;
  }
  

@media(max-width:899px){
  .record-breaking-text{
    font-size: 28px;
  }
  .card-sub-title-proposal-section{
    font-size: 14px;
  }
  .card-sub-title-2-proposal-section{
    font-size: 12px;
  }
}

@media(max-width:899px){
    .card-wrapper-container{
        flex-direction: column;
        gap: 3rem;
    }
    .card-wrapper-container .card1{ margin:unset;}
}