.mobile-accordion-nav .swiper-slide {
  max-width: 30rem !important;

}

.mobile-accordion-nav .accordion-item:last-child .top img{
  object-fit: cover !important;
}

.mobile-accordion-nav {
  padding: 1rem 0 0 !important;
}

.details h3 {
  margin-bottom: 0;
}

.details {
  display: flex;
  gap: 10px;
  align-items: center;
}

.closeMenu img {
  width: 30px;
  float: right;
  margin-right: 13px;
  border: 1px solid;
  padding: 5px;
  border-radius: 100%;
  margin-bottom: 1rem;
  cursor: pointer !important;
}

.MuiPaper-root {
  width: 85vw;
}

.mobile-accordion-nav .swiper-wrapper {
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.mobile-accordion-nav .swiper-pagination .swiper-pagination-bullet-active::after {
  content: "";
  background: #15191ff2;
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile-accordion-nav .swiper-pagination .swiper-pagination-bullet-active {
  border: 1px solid #15191ff2;
  background-image: none !important;
  width: 20px !important;
  height: 20px !important;
  padding: 0;
  position: relative;
  background-color: #ababab5e !important;
  outline: unset !important;
}

.mobile-accordion-nav .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #ababab5e !important;
  margin: 0 10px 0 0 !important;
  opacity: 1;
}

.mobile-accordion-nav .swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;

}

.mobile-accordion-nav .swiper-button-next,
.mobile-accordion-nav .swiper-button-prev {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #a6a5a5a3;
  border: none;
  color: #fff;
  font-weight: bold;
  transition: .5s;
}

.mobile-accordion-nav .swiper-button-prev::after,
.mobile-accordion-nav .swiper-button-next::after {
  font-size: 20px !important;
}

.mobile-accordion-nav .swiper-button-next:hover,
.mobile-accordion-nav .swiper-button-prev:hover {
  background-color: #fff;
  color: #000;
}

.mobile-accordion-nav .wrapper {
  height: auto;
  background: transparent;
  margin: auto;
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0;
  transform: scale(0.95);
  transition: box-shadow 0.5s, transform 0.5s;

  &:hover {
    transform: scale(1);
    box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
    height: 100%;
    padding: 0;

    .top {
      height: 80%;
      width: 100%;


    }

    .top img {
      width: 100%;
      height: auto;
      aspect-ratio: 1/1;
      object-fit: contain;
    }

    .bottom {
      width: 200%;
      height: 20%;
      transition: transform 0.5s;

      &.clicked {
        transform: translateX(-50%);
      }

      h3 {
        text-transform: uppercase;
        letter-spacing: .3px;
        color: #fff;
        line-height: initial;
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      p {
        margin: 0;
        padding: 0;
      }

      .left {
        height: 100%;
        width: 50%;
        background: #15191ff2;
        position: relative;
        float: left;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .details {
          padding: 20px;
          float: left;
          width: 100%;
        }

      }

    }
  }

  .inside {
    z-index: 9;
    background: linear-gradient(90deg, #FFE259 15.1%, #FFA751 85.42%);
    ;
    width: 140px;
    height: 140px;
    position: absolute;
    top: -70px;
    right: -70px;
    border-radius: 0px 0px 200px 200px;
    transition: all 0.5s, border-radius 2s, top 1s;
    overflow: hidden;

    .icon {
      position: absolute;
      right: 85px;
      top: 85px;
      color: white;
      opacity: 1;
    }

    &:hover {
      width: 100%;
      right: 0;
      top: 0;
      border-radius: 0;
      height: 80%;

      .icon {
        opacity: 0;
        right: 15px;
        top: 15px;
      }

      .contents {
        opacity: 1;
        transform: scale(1);
        transform: translateY(0);
      }
    }

    .contents {
      padding: 5%;
      opacity: 0;
      transform: scale(0.5);
      transform: translateY(-200%);
      transition: opacity 0.2s, transform 0.8s;

      table {
        text-align: left;
        width: 100%;
      }

      h1,
      p,
      table {
        color: white;
      }

      p {
        font-size: 13px;
      }
    }
  }
}

@media (max-width: 800px) {
  /* .header__container{
        position: fixed;
        z-index: 99;
        background-color: transparent !important;
    } */
}