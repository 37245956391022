.comingSoonSection {
  /* background: linear-gradient(rgba(34, 41, 65, 0.45), rgba(46, 55, 86, 0.45)), url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1387378/bg.png'); */
  background-size: cover !important;
  background-position: center !important;
  height: 100%;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.hero-body {
    text-align:center;
    margin-top:3rem;
  .title {
    
    font-weight: 300 !important;
    font-size: 4rem !important;
    letter-spacing: 15px;
    color: #ffffff !important;
  }
  .subtitle {
          color: #ffffff;
    
      font-weight: 300;
      text-transform: uppercase;
      padding-top: 15px;
      letter-spacing: 1.5px;
       margin-bottom:2rem;
       max-width:700px;
       width:100%;
  }

}
.hero-foot {
 
  font-weight: 600;
  margin-bottom: 20px;
  .backToHomeBtn{
    color: #2E2E2E;
    font-weight: 600;
    border-radius: 30px;
    padding: 18px 47px;
    background: linear-gradient(90deg, #FFE259 15.1%, #FFA751 85.42%);
    border: none;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: .3px;
    font-size: 16px;
  }
  .backToHomeBtn:hover, .backToHomeBtn:active:focus{
    background: #fff !important;
    box-shadow: 0px 2px 20px 10px #97B1BF !important;
    color: #000 !important;
  }
  a {
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: .7rem;
  }
}

/* -------------------- */
/* -- Animations CSS -- */
/* -------------------- */

@keyframes fadeIn {
  from {
    opacity: 0;
    opacity: 1\9;
  }
  to {
    opacity: 1;
  }
}

.fade {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 2s;
}

.fade.fadeOne {
  animation-delay: 1.7s;
}

.fade.fadeTwo {
  animation-delay: 2.2s;
}

.fade.fadeThree {
  animation-delay: 4.2s;
  span {
    font-size: .8rem;
  }
}

@media (max-width:749px){
    .hero-body {
        max-width: 370px;
        text-align: center;
        margin-bottom: 2rem;
        .title {
          display: flex;
          flex-direction: column;
        }
      }

}