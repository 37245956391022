.getQuoteSection{
display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: rgba(7, 7, 7, 0.5);
  background-blend-mode: multiply;
  padding: 40px 20px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.getQuoteSection main {
    display: grid;
    grid-template-columns: 30% 70%;
    place-items: center;
    background: rgba(0, 0, 0, 0.37);
    width: 90%;
    border-radius: 20px;
    margin: 4rem 0;
    box-shadow: 0px 2px 10px 3px #060606 !important;
}

/* Left Side */

.getQuoteSection .left-side {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  pointer-events: none;
  border-radius: 20px 0 0 20px;
}

/* Right Side */

.getQuoteSection .right-side {
  padding: 20px;
  padding-bottom: 40px;
}

.getQuoteSection .form-container h2{
    color:#fff !important;
    text-align: center;
}
.getQuoteSection .form-container .form-label{
    color:#fff !important;
}
.getQuoteSection .form-container  button:hover{
        background: #fff !important;
        box-shadow: 0px 2px 20px 10px #97B1BF !important;
        color: #000 !important;
      }

@media (max-width:769px){
    .getQuoteSection .left-side {
        display:none;
    }
    .getQuoteSection main{grid-template-columns: 100%; }
    .getQuoteSection{
      padding: 40px 0;
    }
}




