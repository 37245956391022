
footer a{
    text-decoration: none;
    height: fit-content;
}

footer{
    text-align: center;
    text-transform: uppercase;
    padding-top: 30px;
    margin-top: auto;
    background-size: cover;
    background-color: rgba(7, 7, 7, 0.85);
    background-blend-mode: multiply;
    background-size: cover !important;
  background-position: center !important;
}

footer li{
    list-style: none;
    margin: 10px;
    display: inline-block;
}


footer .menu a{
    color: #ffffff;
    text-transform: capitalize;
}
footer .menu a:hover{
    color: #FF973B;
    transition: 0.5s;
}

footer .footer-copyright{
    background-color:#15191ff2;
    color: #ffffff;
    padding: 15px;
    margin-top: 30px;
    text-transform: capitalize;
}

footer .footer-copyright p{
    margin-bottom: 0px;
}

.social_links
{
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.social_links .combo
{
    transform: scale(0.9);
    transition: 0.4s ease-in;
    
}
.combo
{
    color: #f9f9f9;
    transition: 0.4s ease-in;
    font-size: 3em;
}
.combo .icon
{
    color: #444343;
    font-size: 2rem;
    transform: translateY(1px);
    transition: 0.2s ease-in;
}
.combo:hover
{
    transform: scale(1.1);
}
.combo:hover , .combo:hover .icon
{
    color:white;
}
.ig:hover , .ig:hover .social__link
{
    background:#e91e63;
}
.fb:hover , .fb:hover .social__link
{
    background:#2196f3;
}

.tw:hover , .tw:hover .social__link
{
    background:#2196f3;
}


footer .social__link{
    max-width: 56px;
  height: 100%;
  background: #eee1e157;
  display: flex;
  justify-content: center;
  padding: 1rem;
  border-radius: 199px;
  width: 100%;
  }