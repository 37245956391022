  .contactFormSection {
    padding: 5rem 1rem;
    background: linear-gradient(180deg, #1f252f, #15191ff2 49.48%, #232c3a);
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;

  }

  .contactFormSection a {
    display: inline-block;
    text-decoration: none;
  }

  input {
    outline: none !important;
  }

  .contactFormSection h1 {
    margin-bottom: 1rem;
    font-size: 30px;
    font-weight: bolder;
    color: #000;
    text-align: left;
    text-transform: uppercase;
  }

  .contactFormSection p {
    margin-bottom: 1rem;
    text-align: left;
    font-style: italic;
    font-size: 16px;
  }

  section#formHolder {
    padding: 50px 0;
    width: 90%;
    margin: auto;
  }

 
  .brand {
    padding: 20px;
    background-size: cover;
    background-position: center center;
    color: #fff;
    min-height: 540px;
    position: relative;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.6s cubic-bezier(1.000, -0.375, 0.285, 0.995);
    z-index: 2;
  }



  .brand::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.85);
    z-index: -1;
  }

  .brand a.logo {
    color: #e4dfdf;
    font-size: 20px;
    font-weight: 700;
    text-decoration: none;
    line-height: 1em;
  }

  .brand textContainer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;
    transition: all 0.6s;
    padding: 0 3rem;
  }



  .brand .heading h2 {
    font-size: 35px;
    line-height: 1.1;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0px;
    padding: 1rem 0 .5rem;
  }

  .brand .heading span {
    color: #FF973B;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.3px;
  }

  .brand .followContainer h3 {
    color: #fff;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.3px;
    padding: 1rem 0 .5rem;
  }

  .brand .detailContainer span {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.3px;
    padding: 1rem 0 .5rem;
  }

  .brand .detailContainer p,
  .brand .detailContainer a {
    font-size: 16px;
    margin-bottom: 1em;
    font-style: unset;
    color: #cecdcd;
  }

  .brand .followContainer .ul-footer-common-parent {
    display: flex;
    gap: .5rem;
  }

  .social__link {
    max-width: 56px;
    height: 100%;
    background: #eee1e157;
    display: flex;
    justify-content: center;
    padding: 1rem;
    border-radius: 199px;
    width: 100%;
  }

  .brand .followContainer .social_links {
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;
    padding-bottom: 1rem;
  }

  .social_links .combo {
    transform: scale(0.9);
    transition: 0.4s ease-in;

  }

  .combo {
    color: #f9f9f9;
    transition: 0.4s ease-in;
    font-size: 3em;
  }

  .combo .icon {
    color: #444343;
    font-size: 2rem;
    transform: translateY(1px);
    transition: 0.2s ease-in;
  }

  .combo:hover {
    transform: scale(1.1);
  }

  .combo:hover,
  .combo:hover .icon {
    color: white;
  }

  .ig:hover,
  .ig:hover .social__link {
    background: #e91e63;
  }

  .fb:hover,
  .fb:hover .social__link {
    background: #2196f3;
  }

  .tw:hover,
  .tw:hover .social__link {
    background: #2196f3;
  }


  /* Form Area */
  .contactFormSection .form {
    position: relative;
    margin: 2rem auto !important;
    background: #fff;
    padding: 2rem !important;
    border-left: 0;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

  }

  .contactFormSection .form button {
    color: #2E2E2E;
    font-weight: 600;
    border-radius: 30px;
    padding: 18px 47px;
    background: linear-gradient(90deg, #FFE259 15.1%, #FFA751 85.42%);
    border: none;
    width: 100%;
    margin-top: 1rem;
    text-transform: uppercase;
    letter-spacing: .3px;
    font-size: 16px;
  }

  .contactFormSection .form button:hover {
    background: linear-gradient(180deg, #1f252f, #15191ff2 49.48%, #232c3a);
    box-shadow: 0px 2px 20px 10px #97B1BF;
    color: #fff;
  }

  @media (max-width:769px) {
    .brand hr {
      margin: .5rem;
    }
    .brand textContainer{
      padding: 0 1rem;
    }
  }