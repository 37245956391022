.elementor-2680 .elementor-element.elementor-element-e4817c9 {
  margin-top: 5%;
  margin-bottom: 0%;
}
.product-page-super-parent{
  background: #20252D;
}

.elementor-section.elementor-section-boxed>.elementor-container {
  max-width: 1140px;
}

.elementor-section .elementor-container {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
.DesktopBannerTesla{
  display: block;
}
.MobileBannerTesla{
  display: none;
}
@media (min-width: 768px) {

  .elementor-column.elementor-col-50,
  .elementor-column[data-col="50"] {
    width: 100%;
    display: flex;
  }
}

.elementor-2680 .elementor-element.elementor-element-e4817c9>.elementor-container>.elementor-column>.elementor-widget-wrap {
  align-content: center;
  align-items: center;
}

.elementor-2680 .elementor-element.elementor-element-b45e63c>.elementor-element-populated {
  padding: 0px 0px 0px 0px;
}

.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
  display: flex;
}

.elementor-widget-wrap {
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
  margin-left: 58px;
}

.elementor-widget-wrap>.elementor-element {
  width: 100%;
}

.elementor-element {
  --widgets-spacing: 20px 20px;
}

.about-section-brand {
  margin: 80px 10px;
}

/* .product-usp-sectionDesk .carousel {
  height: auto !important;
} */
.product-usp-sectionDesk img{
  width: inherit;
  height: inherit;
  /* height: 100%; */
}
.product-usp-sectionDesk  {
  display: block;
}
.product-usp-sectionMob {
  display: none;
}

.specs-details {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.product-specification {
  padding: 80px 20px 80px 20px;
  background: linear-gradient(180deg, #1f252f, #15191ff2 49.48%, #232c3a);
}

.product-specification h2 {
  font-size: clamp(55px, calc(110*(100vw / 1440)), 110px);
  line-height: 1.1;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0px;
  margin-bottom: 50px;
  text-align: center;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #e1e1e1d4;
  background: transparent;
  color: transparent;
}
.product-specification h3 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600 !important;
  letter-spacing: 1.3px;
  margin: 0;
  margin-bottom: 14px;
}

.product-specification h5, .elementor-heading-title {
  color: #9c9c9c;
}
.product-specification p, .elementor-widget-container p {
  font-size: 14px;
  color: #fff;
  margin-bottom: 3px;
}

.specification-child {
  border-bottom: 1px solid #F1F1F112;
  padding: 28px 0;
}

.product-main-banner{
  width: 100%;
}
.MobileTesla{
  display: none;
}
.DeskTopTesla{
  display: block;
}
.elementor-widget-container h2{
  margin-top: 1rem;
}
@media (max-width:767px) {
  .MobileTesla{
    display: block;
  }
  .DeskTopTesla{
    display: none;
  }
  .custom-banner-section.carousel{
    height: 130vh !important;
  }
  .DesktopBannerTesla{
    display: none;
  }
  
  .MobileBannerTesla{
    display: block;
    height: inherit;
  }
  .custom-banner-section{
    margin-top: 16%;
  }
  .MobBannerContent{
    top:63% !important;
  }
  .MobBannerContent .title{
    font-size: 30px !important;
  }
  .about-section-brand{
    margin: 0;
  }
  .elementor-widget-wrap{
    margin: 0;
  }
  .elementor-column.elementor-col-50.elementor-top-column{
    display: flex;
    flex-direction: column-reverse;
  }
  .elementor-widget-wrap.elementor-element-populated{
    padding: 5%;
  }
  .elementor-heading-title.elementor-size-default{
    font-size: 30px;
  }
  .elementor-widget-container p{
    margin-top: 1rem;
  }
  .elementor-widget-container img{
    border-radius: 10px;
  }
  .product-usp-sectionDesk  {
    /* height: auto !important; */
    display: none;
  }
  .product-usp-sectionMob {
    display: block;
    height: 68vh;
  }
  .swiper-watch-progress.swiper-backface-hidden.product-usp-section-mySwiper.carousel{
    height: 71vh !important;
  }
  .product-usp-sectionMob .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal{
    bottom: 3% !important;
  }
  .product-specification h2{
    font-size: 50px;
  }
  .specs-details{
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
  .product-usp-sectionMob img{
    height: 100% !important;
    width: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 678px) {
  .carousel .list .item .content {
    left: 10%;
    top: 50%;
    transform: translate(-5%, -70%);
  }
}