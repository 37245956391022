.landing__header__main{
    font-size: 45px;
}
.landing__header__discount{
    font-size: 14px;
}
.landing__header__button{
    font-size: 13px !important;
}

.landing__container {
    width: 100%;
    min-height: 420px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    background-color: #0c1d51;
    position: relative;
    overflow:hidden;
}


.landing__header a {
    text-decoration: none;
    color: #fff;
   
}
.landing__header button{
    color: #2E2E2E;
    border-radius: 30px;
    padding: 18px 47px;
    background: linear-gradient(90deg, #FFE259 15.1%, #FFA751 85.42%);
    border: none;
    margin-top: 1rem;
    text-transform: uppercase;
    letter-spacing: .3px;
}

.landing__header button:hover{
    background: #fff;
    box-shadow: 0px 2px 20px 10px #97B1BF;
    color: #000;
}
.landing__header__container {
    width: 50%;
  height: auto;
  min-height: 450px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 20;
  left: 2rem;
}

.landing__header {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.landing__header__main {
    line-height: 1.3em;
    color:#fff;
}

.landing__header__discount {
    color:#fff;
}

.landing__button {
    width: 60%;
    cursor: pointer;
    transition: .3s;
    color:#fff;
}

.landing__image__container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: brightness(45%) hue-rotate(329.8deg);
}

.landing__image {
    height: auto;
    width: 100%;
    top: 0%;
    z-index: 10;
}

@media (max-width:800){
    .landing__header__main{
        font-size: 32px;
    }
    .landing__header__discount{
        font-size: 12px;
    }
    .landing__header__button{
        font-size: 13px !important;
    }
}

@media(max-width:899px)
{
    .landing__image{
        height: 500px;
        width: unset;
    }
    .landing__header__container{
        width:100%;
        left:5px;
        padding: 2rem;
    }
}