.our-packages-section {
  background: linear-gradient(0deg, #1f252f, #15191ff2 49.48%, #232c3a);
  /* margin: 3rem 0; */
  padding: 3REM;
}

.packagesSwiperInside{
  height: 300px;
  width: 300px;
  display: flex;
  justify-content: center;
}

.packagesSwiperInside img{
  width: initial;
  height: inherit;
}

.our-packages-section .title {
  font-size: 16px;
}

.our-packages-section .des li {
  font-size: 14px;
}

.our-packages-section .buttons button {
  font-size: 13px;
}

.our-packages-section h2 {
  font-size: clamp(55px, calc(110*(100vw / 1440)), 110px);
  line-height: 1.1;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0px;
  margin-bottom: 50px;
  text-align: center;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #e1e1e1d4;
  background: transparent;
  color: transparent;
}

.our-packages-section .list {
  border-radius: 20px;
  padding: 1rem;
  border: 2px solid #fff;
}


.our-packages-section .swiper-wrapper {
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.our-packages-section .swiper-pagination .swiper-pagination-bullet-active::after {
  content: "";
  background: #ffffff;
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.our-packages-section .swiper-pagination .swiper-pagination-bullet-active {
  border: 1px solid #fff;
  background-image: none !important;
  width: 20px !important;
  height: 20px !important;
  padding: 0;
  position: relative;
  background-color: #0000 !important;
  outline: unset !important;
}

.our-packages-section .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #ababab5e !important;
  margin: 0 10px 0 0 !important;
  opacity: 1;
}

.our-packages-section .swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;

}

.our-packages-section .item img {
  border-radius: 8px;
}

.our-packages-section .item .content {
  text-align: left;
  margin-top: 1rem;
  color: #fff;
}

.our-packages-section .item .content .title {
  color: #fff;
  /* text-align: center; */
  /* font-size: 22px; */
  text-transform: uppercase;
  font-weight: 600 !important;
  letter-spacing: 1.3px;
  margin-bottom: .5rem;

}

.our-packages-section .item .content .buttons {
  display: flex;
  justify-content: center;
  width: 100%;
}

.our-packages-section .item .content .buttons a {
  width: 100%;
}

.our-packages-section .item .content .buttons button {
  color: #2E2E2E;
  /* font-weight: 600; */
  border-radius: 30px;
  padding: 18px 47px;
  background: linear-gradient(90deg, #FFE259 15.1%, #FFA751 85.42%);
  border: none;
  width: 100%;
  margin-top: 1rem;
  text-transform: uppercase;
  letter-spacing: .3px;
}

.our-packages-section .item .content .buttons button:hover {
  background: #fff;
  box-shadow: 0px 2px 20px 10px #97B1BF;
  color: #000;
}

.our-packages-section ul.checkmark li {
  /* font-size: 16px; */
  margin-bottom: 1em;
  list-style-type: none;
  padding: .25em 0 0 2.5em;
  position: relative;
}

.our-packages-section ul.checkmark li:before {
  content: " ";
  display: block;
  border: solid .8em #FF973B;
  ;
  border-radius: .8em;
  height: 0;
  width: 0;
  position: absolute;
  left: .5em;
  top: 40%;
  margin-top: -.5em;
}

.our-packages-section ul.checkmark li:after {
  content: " ";
  display: block;
  width: .5em;
  height: .8em;
  border: solid #fff;
  border-width: 0 .2em .2em 0;
  position: absolute;
  left: 1em;
  top: 40%;
  margin-top: -.25em;
  transform: rotate(45deg);
}

@media (max-width:800px){
  .our-packages-section .title {
    font-size: 14px;
  }
  
  .our-packages-section .des li {
    font-size: 12px;
  }
  
  .our-packages-section .buttons button {
    font-size: 13px;
  }
}

@media(max-width:749px) {
  .our-packages-section h2 {
    margin-bottom: 2rem;

  }

  .our-packages-section {
    padding: 2rem
  }
}