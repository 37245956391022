.ArticleMain {
    margin: 6% 8%;
}

.ArticleContainer {
    width: 75%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
}

.ArticleImageContainer {
    width: 100%;
}

.ArticleImageContainer img {
    width: 100%;
    height: auto;
    border-radius: 30px;
}

.ArticleContent {
    width: 90%;
    margin: 5% auto;
    display: flex;
    flex-direction: column;
    gap: 5%;
}

.ArticleContent p,
.ArticleContent h2,
.ArticleContent .ArticleHighlightMessage,
.ArticleContent ul {
    margin-bottom: 3%;
}

.ArticleContent p {
    font-size: 17px;
    line-height: 1.6em;
    color: #000;
    /* font-weight: 500; */
}

.ArticleContent h2,
.ArticleContent h3 {
    font-size: 26px;
    color: black;
    font-weight: 900;
    font-style: bold;
}

.ArticleHighlightMessage {
    padding: 4% 8%;
    background-color: rgb(63, 63, 63);
    border-radius: 30px;
}

.ArticleHighlightMessage div {
    color: darkgrey;
    font-size: 40px;
    font-style: bold;
    font-weight: 700;
    line-height: 1.2em;
}

.ArticleContent {
    position: relative;
}

.ArticleContent li {
    margin-top: 10px;
    list-style-type: disc;
    margin-left: 20px;
    font-weight: 400;
}

@media (max-width:768px) {
    .ArticleMain {
        margin: 6% 4%;
        min-height: 100vh;
        margin-top: 18%;
    }

    .ArticleContainer {
        width: 100%;
    }

    .ArticleContent p,
    .ArticleContent h2,
    .ArticleContent .ArticleHighlightMessage,
    .ArticleContent ul {
        margin-bottom: 5%;
    }

    .ArticleContent p {
        font-size: 14px;
        line-height: 1.4em;
    }
    .ArticleContent h2,
.ArticleContent h3 {
    font-size: 20px;
}
.ArticleHighlightMessage div {
    font-size: 14px;
}
.ArticleContent li {
  font-size: 14px;
}
}
@media (max-width:500px) {
    .ArticleMain {
        margin-top: 25%;
    }
}