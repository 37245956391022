.trusted-logo-parent {
    gap: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 4rem 0;
}

.particular-logo {
    width: 105px;
    background: white;
    height: 105px;
    display: flex;
    align-items: center;
}