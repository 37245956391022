a {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.nav.nav-parent-custom .nav-link.mega-menu-label:last-child .top img{
  object-fit: cover;
}

.header-menu-label {
  font-size: 14px;
}

.nav__bottom__container {
  margin: 0;
  padding: 0;
}

.details h3 {
  margin-bottom: 0;
}

.details {
  display: flex;
  gap: 10px;
  align-items: center;
}

.FeaturedProductSection h2 {
  /* font-size: clamp(55px, calc(77*(100vw / 1440)), 77px); */
  line-height: 1.1;
  /* font-weight: 700; */
  color: #15191fb8;
  text-transform: uppercase;
  letter-spacing: 0px;
  margin-bottom: 50px;
  text-align: center;
}



.megaMenuSection .swiper-wrapper {
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.megaMenuSection .swiper-pagination .swiper-pagination-bullet-active::after {
  content: "";
  background: #15191ff2;
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.megaMenuSection .swiper-pagination .swiper-pagination-bullet-active {
  border: 1px solid #15191ff2;
  background-image: none !important;
  width: 20px !important;
  height: 20px !important;
  padding: 0;
  position: relative;
  background-color: #ababab5e !important;
  outline: unset !important;
}

.megaMenuSection .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #ababab5e !important;
  margin: 0 10px 0 0 !important;
  opacity: 1;
}

.megaMenuSection .swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;

}

.megaMenuSection .swiper-button-next,
.megaMenuSection .swiper-button-prev {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #a6a5a5a3;
  border: none;
  color: #fff;
  font-weight: bold;
  transition: .5s;
}

.megaMenuSection .swiper-button-prev::after,
.megaMenuSection .swiper-button-next::after {
  font-size: 20px !important;
}

.megaMenuSection .swiper-button-next:hover,
.megaMenuSection .swiper-button-prev:hover {
  background-color: #fff;
  color: #000;
}

.megaMenuSection .wrapper {
  height: auto;
  background: transparent;
  margin: auto;
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #e6e6e6;
  box-shadow: 2px 11px 7px #d7dadd;
  transform: scale(0.95);
  transition: box-shadow 0.5s, transform 0.5s;

  &:hover {
    transform: scale(1);
    box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
    height: 100%;
    padding: 0;

    .top {
      height: 80%;
      width: 100%;


    }

    .top img {
      width: 100%;
      height: auto;
      aspect-ratio: 1/1;
      object-fit: contain;
    }

    .bottom {
      width: 200%;
      height: 20%;
      transition: transform 0.5s;

      &.clicked {
        transform: translateX(-50%);
      }

      h3 {
        text-transform: uppercase;
        letter-spacing: .3px;
        color: #fff;
        line-height: initial;
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      p {
        margin: 0;
        padding: 0;
      }

      .left {
        height: 100%;
        width: 50%;
        background: #15191ff2;
        position: relative;
        float: left;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .details {
          padding: 20px;
          float: left;
          width: 100%;
        }

      }

    }
  }

  .inside {
    z-index: 9;
    background: linear-gradient(90deg, #FFE259 15.1%, #FFA751 85.42%);
    ;
    width: 140px;
    height: 140px;
    position: absolute;
    top: -70px;
    right: -70px;
    border-radius: 0px 0px 200px 200px;
    transition: all 0.5s, border-radius 2s, top 1s;
    overflow: hidden;

    .icon {
      position: absolute;
      right: 85px;
      top: 85px;
      color: white;
      opacity: 1;
    }

    &:hover {
      width: 100%;
      right: 0;
      top: 0;
      border-radius: 0;
      height: 80%;

      .icon {
        opacity: 0;
        right: 15px;
        top: 15px;
      }

      .contents {
        opacity: 1;
        transform: scale(1);
        transform: translateY(0);
      }
    }

    .contents {
      padding: 5%;
      opacity: 0;
      transform: scale(0.5);
      transform: translateY(-200%);
      transition: opacity 0.2s, transform 0.8s;

      table {
        text-align: left;
        width: 100%;
      }

      h1,
      p,
      table {
        color: white;
      }

      p {
        font-size: 13px;
      }
    }
  }
}

@media (max-width:800px) {
  .mobile-accordion-nav button {
    font-size: 12px;
  }
}

@media screen and (max-width:749px) {
  .megaMenuSection {
    padding: 1.5rem;
  }
}

.nav-link-custom {
  flex-basis: 200px;
}

.nav-child-container .swiper {
  width: 100vw;
}

.nav-parent-custom li:nth-child(2) .nav-child-container {
  margin-left: -9vw;
}

.nav-parent-custom li:nth-child(3) .nav-child-container {
  margin-left: -19.5vw;
}

.nav-parent-custom li:nth-child(4) .nav-child-container {
  margin-left: -30vw;
}

.nav-parent-custom li:nth-child(5) .nav-child-container {
  margin-left: -38vw;
}

.nav-child-container {
  width: 50vw;
  background: #fff;
  box-shadow: 0 1px 10px rgba(0, 0, 0, .2);
  border-radius: 8px;
  /* display: none; */
  display: flex;
  visibility: hidden;
  position: absolute;
  transition: visibility .4s, opacity .4s ease, transform .4s cubic-bezier(.33, 0, .6, 1.58);
  opacity: 0;
  transform: translateY(-20px);
  padding: 0 1rem 1rem;
}

.nav-parent-custom {
  width: 40rem;
}

.mega-menu-label {
  position: relative;
  cursor: pointer;
}

.mega-menu-label>a {
  color: white;
}

.mega-menu-label:hover .nav-child-container {
  visibility: visible;
  transform: translateY(12.99px);
  opacity: 1;
}

.header-menu-label:hover+.nav-child-container {
  visibility: visible;
  transform: translateY(12.99px);
  opacity: 1;
}

a:hover {
  color: black;
}

.nav__bottom__container .nav-child-container a {
  text-decoration: none;
  color: rgb(3, 3, 3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__bottom__container a:hover {
  color: #fff;
  text-decoration: underline;
  text-underline-offset: 9px;
}

.nav__bottom__container {
  height: 50px;
  background-color: #15191ff2;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 100;
}

.bottom__container {
  margin-right: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.container .nav-link {
  color: black;
}

.control__small {
  width: 50%;
  height: auto;
  display: none;
}

@media (max-width:1537px) {
  .nav__bottom__container a:hover {
    text-decoration: none;
  }

}

@media screen and (max-width: 780px) {
  .nav__bottom__container {
    display: none;
  }
}

@media screen and (max-width: 780px) {
  .control__small {
    display: flex;
    margin: auto 10px;
  }
}