.accordion1 {
    display: flex;
    max-width: 1600px;
  width: 100%;
  gap: 3rem;
  padding: 5rem 4rem;
  padding-left:0;

  }
  .usp-section-custom h3{
    font-size: 32px;
  }
  .usp-section-custom .answer{
    font-size: 16px;
  }
  
  .accordion1 .accordion-button{
    display:flex;
    justify-content: space-between;
  }
  .accordion-questions {
    flex: 1;
  }
  
  .accordion-question {
    margin-bottom: 10px;
    overflow: hidden;
    border: 0 !important;
  background: transparent !important;
  }
  
  .accordion-question[aria-expanded="true"]{
    border-color: #f1683a;
  }
  
  .question {
    cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 0.1rem solid #e4e4e4;
  padding: .5rem 0rem;
  }
  /* .question h3{
    font-weight: 400;
  line-height: 1.25;
  letter-spacing: -.03em;
  font-size: clamp(23.4666666667px, 23.4666666667px +(58.6666666667 - 23.4666666667)*(100vw - 1024px) /(2560 - 1024), 58.6666666667px);
  } */
  
  .answer {
    padding: 2rem;
    margin-top: 10px;
    overflow: hidden;
  
  }
  
  .accordion-image {
    flex: 1;
  }
  
  .accordion-image img {
    max-width: 100%;
    height: auto;
  }
  .accordion-image {
    transition: opacity 0.5s ease; /* Add other transition properties as needed */
  }
  
  .accordion-image.show {
    opacity: 1;
  }
  .accordion1 .accordion-button::after{
    content: unset;
  }
 @media (max-width:800px){
  usp-section-custom h3{
    font-size: 25px;
  }
 }
  
  @media(max-width:769px)
{
  .accordion1 {flex-direction: column;padding: 2rem;}
  .usp-section-custom h3{ font-size: 26px;}
  .question{padding: 0rem 0;}
  .answer {
    padding: 2rem 0;
  }
}







