
.wishlist {
    height: auto;
    min-height: 65vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
}

.wishlist__container {
    height: auto;
    width: 70%;
    background-color: rgb(243, 243, 243);
}

.wishlist__header{
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wishlist__header>h2  {
    width: auto;
}

.wishlist__items__container {
    width: 100%;
    padding: 20px;
}

.wishlist__items {
    width: 100%;
    min-height: 50px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.wishlist__item__label {
    width: 100%;
    height: 100%;
    padding: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border-style: solid;
}


.wishlist__item__label> div {
}