.FeaturedProductSection {
  margin: 3rem 0;
  padding: 3REM;
}

.details h3{
  margin-bottom: 0;
}
.details {
  display: flex;
  gap: 10px;
  align-items: center;
}

.FeaturedProductSection .contents {
  font-size: 14px;
}

.FeaturedProductSection .details h3 {
  font-size: 16px;
}

.FeaturedProductSection h2 {
  font-size: 45px;
  /* font-size: clamp(55px, calc(77*(100vw / 1440)), 77px); */
  line-height: 1.1;
  /* font-weight: 700; */
  color: #15191fb8;
  text-transform: uppercase;
  letter-spacing: 0px;
  margin-bottom: 50px;
  text-align: center;
}


.FeaturedProductSection .swiper-wrapper {
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.FeaturedProductSection .swiper-pagination .swiper-pagination-bullet-active::after {
  content: "";
  background: #15191ff2;
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.FeaturedProductSection .swiper-pagination .swiper-pagination-bullet-active {
  border: 1px solid #15191ff2;
  background-image: none !important;
  width: 20px !important;
  height: 20px !important;
  padding: 0;
  position: relative;
  background-color: #ababab5e !important;
  outline: unset !important;
}

.FeaturedProductSection .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #ababab5e !important;
  margin: 0 10px 0 0 !important;
  opacity: 1;
}

.FeaturedProductSection .swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;

}

.FeaturedProductSection .swiper-button-next,
.FeaturedProductSection .swiper-button-prev {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #a6a5a5a3;
  border: none;
  color: #fff;
  font-weight: bold;
  transition: .5s;
}

.FeaturedProductSection .swiper-button-prev::after,
.FeaturedProductSection .swiper-button-next::after {
  font-size: 20px !important;
}

.FeaturedProductSection .swiper-button-next:hover,
.FeaturedProductSection .swiper-button-prev:hover {
  background-color: #fff;
  color: #000;
}

.FeaturedProductSection .wrapper {
  height: 500px;
  background:transparent ;
  margin: auto;
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #e6e6e6;
  box-shadow: 2px 11px 7px #d7dadd;
  transform: scale(0.95);
  transition: box-shadow 0.5s, transform 0.5s;

  &:hover {
    transform: scale(1);
    box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
    height: 100%;
    padding: 0;

    .top {
      height: 80%;
      width: 100%;


    }
    .top img{
      width:100%;
      height:auto;
      aspect-ratio: 1/1 ;
      object-fit: contain;
    }

    .bottom {
      width: 200%;
      height: 20%;
      transition: transform 0.5s;

      &.clicked {
        transform: translateX(-50%);
      }

      h3 {
        text-transform: uppercase;
        letter-spacing: .3px;
        color: #fff;
        line-height: initial;
        font-weight: 600 !important;
      }
      .left{
        height:100%;
        width: 100%;
        background:#15191ff2;
        position:relative;
        float:left;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .details {
          padding: 20px;
          float: left;
          width: 100%;
        }

        .buy {
          float: right;
          width: calc(30% - 2px);
          height: 100%;
          background: #15191ff2;
          transition: background 0.5s;
          border-left: solid thin rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            padding: 30px;
            transition: transform 0.5s;
          }

          &:hover {

            background: linear-gradient(180deg, #1f252f, #15191ff2 49.48%, #232c3a);
          }

          &:hover i svg path {
            transform: translateY(5px);
            fill: #FF973B;
          }
        }
      }
      .right{
        display:none;
        width: 50%;
        background: #A6CDDE;
        color: white;
        float: right;
        height: 200%;
        overflow: hidden;

        .details {
          padding: 20px;
          float: right;
          width: calc(70% - 40px);

        }

        .done {
          width: calc(30% - 2px);
          float: left;
          transition: transform 0.5s;
          border-right: solid thin rgba(255, 255, 255, 0.3);
          height: 50%;

          i {
            font-size: 30px;
            padding: 30px;
            color: white;
          }
        }
      
       
        &:hover{
          .remove, .done{
            transform: translateY(-100%);
          }
        }
      }
    }
  }

  .inside {
    z-index: 9;
    background: linear-gradient(90deg, #FFE259 15.1%, #FFA751 85.42%);
    ;
    width: 140px;
    height: 140px;
    position: absolute;
    top: -70px;
    right: -70px;
    border-radius: 0px 0px 200px 200px;
    transition: all 0.5s, border-radius 2s, top 1s;
    overflow: hidden;

    .icon {
      position: absolute;
      right: 85px;
      top: 85px;
      color: white;
      opacity: 1;
    }

    &:hover {
      width: 100%;
      right: 0;
      top: 0;
      border-radius: 0;
      height: 80%;

      .icon {
        opacity: 0;
        right: 15px;
        top: 15px;
      }

      .contents {
        opacity: 1;
        transform: scale(1);
        transform: translateY(0);
      }
    }

    .contents {
      padding: 5%;
      opacity: 0;
      color:#000;
      transform: scale(0.5);
      transform: translateY(-200%);
      transition: opacity 0.2s, transform 0.8s;

      table {
        text-align: left;
        width: 100%;
      }

      h1,
      p,
      table {
        color: white;
      }

      p {
        font-size: 13px;
      }
    }
  }
}

@media (max-width:800px) {
  .FeaturedProductSection .contents {
    font-size: 12px;
  }

  .FeaturedProductSection .details h3 {
    font-size: 14px;
  }

  .FeaturedProductSection h2 {
    font-size: 32px;
  }
}

@media screen and (max-width:749px) {
  .FeaturedProductSection {
    padding: 1.5rem;
  }
}