.BlogMain {
    margin: 6% 8%;
}

.BlogsContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.BlogMainHeading{
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-style: bold;
    margin-bottom: 3%;
}
.Blogs {
    width: 25%;
}

.Blog-image {
    position: relative;
}

.BlogContent {
    position: absolute;
    width: 100%;
    bottom: 3%;
}

.Blog-content-Inner {
    background-color: white;
    width: 90%;
    border-radius: 20px;
    margin: auto;
    padding: 3% 5%;
}

.Blog-image img {
    width: 100%;
    height: 240px;
    border-radius: 20px;
    object-fit: cover;
}

.blogDescription {
    text-align: left;
    font-weight: 500;
    font-style: bold;
}

.bottomBlogContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.BlogDateContainer {
    display: flex;
    align-items: center;
    gap: 2%;
    width: 70%;
}

.BlogDateContainer .BlogCalenderImageContainer {
    width: 12%;
}

.BlogDateContainer .BlogCalenderImageContainer img {
    width: 100%;
    height: auto;
}

.blogDate {
    color: gray;
    font-size: 14px;
    margin-top: 2px;
    font-weight: 400;
    font-style: bold;
}

.blogViewMore {
    color: gray;
    font-size: 14px;
    width: 30%;
    font-weight: 400;
    font-style: bold;
}
.blogViewMore:hover{
    color: black;
}

@media (max-width:768px) {
    .BlogMain {
        min-height: 100vh;
        margin-top: 15%;
    }
    .BlogMainHeading{
        margin-bottom: 8%;
    }
    .BlogsContainer{
        flex-direction: column;
        gap: 10%;
    }
    .Blogs{
        width: 90%;
        margin-bottom: 15%;
    }
}

@media (max-width:500px) {
    .BlogMain {
        margin-top: 25%;
    }
    .BlogMainHeading{
       font-size: 25px;
    }
}