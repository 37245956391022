.announcement-parent {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background:#fff;
}
.announcement-parent p, .announcement-parent button {
    font-size: 15px;
}

.announcement-child {
    display: flex;
    align-items: center;
    justify-content: center;
}
.announcement-child button{
    color: #2E2E2E;
  font-weight: 600;
  border-radius: 30px;
  padding: 18px 47px;
  background: linear-gradient(90deg, #FFE259 15.1%, #FFA751 85.42%);
  border: none;
  text-transform: uppercase;
  letter-spacing: .3px;

}
.announcement-child button:hover, .announcement-child button:active:focus{
    background: #15191ff2 !important;
  box-shadow: 0px 1px 2px 1px #97B1BF !important;
  color: #fff !important;
}
.announcement-parent .announcement-child:first-child{
    gap: 5px;
}

.announcement-parent .announcement-child button{
    height: 50px;

}

@media (max-width:800px){
    .announcement-parent p, .announcement-parent button {
        font-size: 11px;
  }
}
@media screen and (max-width: 780px) {
    .announcement-parent{
        display:none;
    }
}