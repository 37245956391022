
.search__form {
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: auto;
    align-self: center;
   
}

.search__form__input {
    width: 100%;
    height: 45px;
    /* border-radius: 30px; */
    outline: none;
    padding-left: 25px;
    font-size: .9rem;
    border-color: #FFE26E;
    border-style: solid;
    border-radius: 10px;
}

.search__form__button {
    position: absolute;
    height: 45px;
    width: 50px;
    background-color: #FFE26E;;
    color: rgb(0, 0, 0);
    right: 0px;
    font-weight: bold;
    border-color: #FFE26E;
    border-style: solid;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.search__icon {
    height: 50%;
    width: auto;
}

@media screen and (max-width: 600px) {
    .search__form {
        width: 200px;
    }
}


