.form-container h2 {
    text-align: left;
    font-size: 32px;
    line-height: 1.1;
    /* font-weight: 900; */
    color: #15191ff2;
    text-transform: uppercase;
    letter-spacing: 0px;
    padding: 1rem 0 .5rem;
    margin: 2rem auto;
}

.forms-InfoContainer label {
    font-size: 14px;
}

.forms-InfoContainer button {
    font-size: 13px;
}

.form-container .form-label {
    text-transform: uppercase;
}

.form-container button {
    color: #2E2E2E;
    font-weight: 600;
    border-radius: 30px;
    padding: 18px 47px;
    background: linear-gradient(90deg, #FFE259 15.1%, #FFA751 85.42%);
    border: none;
    width: 100%;
    margin-top: 1rem;
    text-transform: uppercase;
    letter-spacing: .3px;
    /* font-size: 16px; */
}
.form-container button:focus {
    color: #2E2E2E !important;
    border-color: unset !important;
    outline: 0;
    box-shadow: none !important;
  }
.form-container button:hover{
    background: linear-gradient(180deg,#1f252f,#15191ff2 49.48%,#232c3a);
    color: #fff;
}

@media (max-width:800px) {
    .form-container h2 {
        font-size: 26px;
        padding:0;
    }

    .forms-InfoContainer label {
        font-size: 12px;
    }
}