
.InfoContainer {
   width: 100%;
  background: linear-gradient(0deg, #1f252f, #15191ff2 49.48%, #232c3a);
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
}
.InfoContainer .section-title{
  font-size: clamp(40px, calc(77*(100vw / 1440)), 77px);
  line-height: 1.1;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0px;
  margin-top: 50px;
  text-align: center;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #e1e1e1d4;
  background: transparent;
  color: transparent;
}
.InfoContainer .content p{
  font-size:clamp(16px, calc(20*(100vw / 1440)), 20px);
  padding: 2rem 1rem;
  text-align:center;
  color:#fff;
}
.InfoContainer .form-content-div{
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0 3rem;
}



.InfoContainer .left-box {
  background: linear-gradient(90deg, #FFE259 15.1%, #FFA751 85.42%);
  width: 480px;
  /* height: 600px; */
  margin: 3em 0;
  box-shadow: -11px -7px 73px 0px #232c3a;
  position: relative;
  border-radius: .5em  .5em;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* transform: rotate(-10deg) translate(-20%, 40%); */
    position: absolute;
    left: 0;
    top: 0;
    border-radius: .5em;
  }
  
}

.InfoContainer .right-box {
  background: #fff;
  max-width: 800px;
  width:100%;
   box-shadow: 0px 20px 80px 0px #232c3a;
  margin: 3.8em 0rem 4.8em;
  padding: 2rem;
  border-radius: 0 .5em .5em 0em;
}
.InfoContainer .right-box  .form-container h2{
  margin-top: 0;
}

@media only screen and (max-width: 769px) {
  .InfoContainer .form-content-div {
    display: block;
    overflow: hidden;
    padding: 0 1rem;
  }
  .InfoContainer .right-box {
     width: 100%;
     border-radius: .5em;
     margin-top: 1rem;
  }
  .InfoContainer .left-box{
     display: none;
  }
  .InfoContainer .right-box  .form-container h2{
   text-align: center;
  }
}