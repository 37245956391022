body, #root{
    overflow-x: hidden;
}

.nav__top__container {
    height: 70px;
    width: 100%;
    display: flex;
    
}
.getQuoteBTN{
    color: #2E2E2E !important;
  font-weight: 600 !important;
  border-radius: 30px !important;
  padding: 18px 30px !important;
  background: linear-gradient(90deg, #FFE259 15.1%, #FFA751 85.42%);
  border: none !important;
  text-transform: uppercase;
  letter-spacing: .3px !important;
  margin: .5rem 0;
}
.getQuoteBTN:hover{
    background: #15191ff2 !important;
  box-shadow: 0px 1px 2px 1px #97B1BF;
  color: #fff !important;
}

.top__container {
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: space-between;
   padding-right:1.5rem;
}

.form__container {
    width: 50%;
    min-height: 10px;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    align-self: center;
}

.control__bar {
    align-self: center;
    height: auto;
    width: auto;
    z-index: 1000;
}

.drawer {
    display: none;
    height: auto;
    width: 80px;
    justify-content: center;
    align-items: center;
}
.mobile-accordion-nav{
    padding: 3rem 0;
}
.mobile-accordion-nav .accordion-header .accordion-button{
    text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
.mobile-accordion-nav .accordion-item{
   border:0;
}
@media screen and (max-width: 500px) {
    .nav__top__container {
        height: auto;
    }
    .form__container {
        display: none;
    }
    .top__container {
        justify-content: space-between;
        align-items: center;
    }
}

@media screen and (max-width: 780px) {
    .control__bar{
       display: none;
    }
    .drawer{
        display: flex;
    }
    .getQuoteBTN{
        padding: 10px 20px !important;
    }
    .top__container{
        padding-right:0;

    }
}