.header__container {
    height: auto;
    width: 100%;
    background-color: #ffffff;
    font-family: 'Roboto', sans-serif;
    box-shadow: 0px 7px 38px -26px;
    z-index: 99;
    background: transparent;
        position: fixed;
        top: 68px;
}

.nav__top__container{
    display: none;
}
nav .bottom__container  .nav-parent-custom{
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    width:100% !important;
}
@media (max-width:780px) {
    .header__container {
        top: 0px !important;
    }
    .header__container .top__container{
        background:#fff;
        padding-right: 15px;
    }
    .nav__top__container{
        display: flex;
    }
}