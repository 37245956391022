.our-eco-system {
    max-width: 1600px;
    padding: 5rem 4rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.our-eco-system-heading{
    font-size: 45px;
}
.our-eco-system-textcontent{
    font-size: 14px;
}

.our-eco-system-brand-logo {
    width: 200px;
}

.our-eco-system-left {
    display: flex;
    flex-direction: column;
    align-self: center;
}

.our-eco-system-right {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
}


.our-eco-system-brand-logo {
    cursor: pointer;
    transform: scale(1);
    transition: 0.4s ease-in;
}

.our-eco-system-brand-logo:hover {
    transform: scale(1.1);
}

@media(max-width:800px) {
    .our-eco-system-heading{
        font-size: 32px;
    }
    .our-eco-system-textcontent{
        font-size: 12px;
    }
    .our-eco-system {
        display: block;
    }
}

@media(max-width:600px) {
    .our-eco-system-right {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(max-width:480px) {
    .our-eco-system-right {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    .our-eco-system-brand-logo{
        width: 125px;
    }
}