h1,
h2,
h3,
h4,
h5,
h6,
a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

a,
a:active,
a:focus {
    color: #6f6f6f;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}


img {
max-width: 100%;
height: auto;
}
.about-section{
    background:linear-gradient(180deg,#1f252f,#15191ff2 49.48%,#232c3a);
    padding: 5rem 2rem 3rem;
    
}

.sec-title{
position:relative;
z-index: 1;
margin-bottom:60px;
}

.sec-title .title{
position: relative;
display: block;
font-size: clamp(55px, calc(85*(100vw / 1440)),85px);
    line-height: 1.1;
    font-weight: 700;
     text-transform: uppercase;
    letter-spacing: 0px;
    margin-bottom: 10px;
   -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #e1e1e1d4;
  background: transparent;
  color: transparent;
  margin-top: 2rem;
}

.sec-title h2{
position: relative;
display: block;
padding-bottom: 18px;
  font-size: clamp(25px, calc(35*(100vw / 1440)),35px);
  line-height: inherit;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  color:#fff;
}

.sec-title h2:before{
position:absolute;
content:'';
left:0px;
bottom:0px;
width:50px;
height:3px;
background-color:#d1d2d6;
}

.sec-title .text{
position: relative;
font-size: 16px;
  margin-bottom: 1em;
  font-style: unset;
  color: #fff;
}

.sec-title.light h2{
color: #ffffff;
}

.sec-title.text-center h2:before{
left:50%;
margin-left: -25px;
}

.about-section ul.usp-list{
    display: grid;
   grid-template-columns: 1fr 1fr;
  margin-top: 2rem;
}

.about-section ul.usp-list li{
    font-size: 16px;
    margin-bottom: 1em;
    list-style-type: none;
    padding: .25em 0 0 2.5em;
    position: relative;
    color:#fff;
}

.about-section ul.usp-list li:before {
    content: " ";
    display: block;
    border: solid .8em #FF973B;
    border-radius: .8em;
    height: 0;
    width: 0;
    position: absolute;
    left: .5em;
    top: 40%;
    margin-top: -.5em;
}
.about-section ul.usp-list li:after {
    content: " ";
    display: block;
    width: .5em;
    height: .8em;
    border: solid #fff;
      border-top-width: medium;
      border-right-width: medium;
      border-bottom-width: medium;
      border-left-width: medium;
    border-width: 0 .2em .2em 0;
    position: absolute;
    left: 1em;
    top: 40%;
    margin-top: -.25em;
    transform: rotate(45deg);
}



.about-section{
position: relative;
padding: 120px 0 70px;
}

.about-section .sec-title{
margin-bottom: 45px;
}

.about-section .content-column{
position: relative;
margin-bottom: 50px;
}

.about-section .content-column .inner-column{
position: relative;
padding-left:2rem;
}

.about-section .text{
    font-size: 16px;
    margin-bottom: 1em;
    font-style: unset;
    color: #fff;
}



.about-section .btn-box{
position: relative;
margin: 3rem auto;
}

.about-section .btn-box a{
    color: #2E2E2E;
    font-weight: 600;
    border-radius: 30px;
    padding: 18px 47px;
    background: linear-gradient(90deg, #FFE259 15.1%, #FFA751 85.42%);
    border: none;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: .3px;
    font-size: 16px;
    text-align: center;
}

.about-section .btn-box a:hover{
    background: #fff;
    box-shadow: 0px 2px 20px 10px #97B1BF;
    color: #000;
}

.about-section .image-column{
position: relative;
}



.about-section .image-column .inner-column{
position: relative;
padding-left: 80px;
padding-bottom: 0px;
}

.about-section .image-column .inner-column:before{
content: '';
position: absolute;
width: calc(50% + 80px);
height: calc(100% + 160px);
top: -80px;
left: -3px;
background: transparent;
z-index: 0;
border-image-source: linear-gradient(90deg, #FFE259 15.1%, #FFA751 85.42%);
border-width: 44px;
  border-style: solid;
  border-image-slice: 1;
}

.about-section .image-column .image-1{
position: relative;
}



.about-section .image-column .image-1 img{
box-shadow: 0 30px 50px rgba(8,13,62,.15);
border-radius: 46px;
}

@media screen and (max-width:999px) {
    .about-section .content-column .inner-column{
        margin-top:5rem;
        padding-left:0;

    }
    .about-section{
        margin-top:3rem;
    }
    .sec-title .title{ margin-top: 0;}
    .about-section .image-column .inner-column{ padding-left:65px;}
}