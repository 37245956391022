
.cart__item__card {
    width: 100%;
    min-height: 80px;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #f4f4f4;
    margin: 10px auto;
    position: relative;
    border-radius: 15px;
}

.cart__item__image {
    width: 65px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 5px;
}

.item__image {
    max-width: 100%;
    box-shadow: 0px 0px 1px 0px;
    height: auto;
    transition: 1s;
    background-color: none;
}

.cart__item__detail {
    min-width: 130px;
    width: 30%;
    height: 85px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0%;
}

.cart__item__name {
    width: auto;
    height: auto;
    font-size: 1rem;
    font-weight: bold;
}

.cart__item__price {
    height: 50px;
    width: 20%;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart__item__quantity {
    cursor: pointer;
    width: 100px;
    height:  100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}


.quantity__input {
    width: 30px;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart__item__action {
    height: 40px;
    width: 20%;
    min-width: 120px;
    display: flex;
    justify-content: center;
}

.buy__item__button {
    cursor: pointer;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;
    align-self: center;
    
}

.buy__now__button__cart {
    width: 65%;
    height: 40px;
    border-radius: 25px;
    font-size: 0.8rem;
    background-color: #000000;
    font-weight:bold;
    border-style: none;
    color: #ffffff;
}

.remove__item__icon {
    cursor: pointer;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0%;
    right: 0%;
}