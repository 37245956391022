.carousel{
    height: 100vh !important;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.carousel .list .item{
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 0 0 0;
    overflow: hidden;
}
.carousel .list .item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    filter: brightness(45%) hue-rotate(329.8deg);
}
.carousel .list .item .content{
    position: absolute;
  max-width: 100%;
  left: 35%;
  top: 50%;
  transform: translate(-30%, -50%);
  box-sizing: border-box;
  color: #fff;
  text-shadow: 0 5px 10px #0004;
  text-align: center;
}

.carousel .list .item .title{
    font-size: clamp(50px, calc(65*(100vw / 1440)), 70px);;
    font-weight: bold;
    line-height: 1.3em;
    margin-bottom:1rem;
    text-transform: uppercase;
}
.carousel .list .item .topic{
    color: #f1683a;
}
.carousel .list .item .buttons{
    display: block;
    gap: 5px;
    margin-top: 20px;
}
.carousel .list .item .buttons button{
    color: #2E2E2E;
    font-weight: 600;
    border-radius: 30px;
    padding: 18px 47px;
    background: linear-gradient(90deg, #FFE259 15.1%, #FFA751 85.42%);
    border: none;
    margin-top: 1rem;
    text-transform: uppercase;
    letter-spacing: .3px;
}
.carousel .list .item .buttons button:hover{
   
        background: #fff;
        box-shadow: 0px 2px 20px 10px #97B1BF;
        color: #000;
   
}



.carousel .swiper-button-next, .carousel .swiper-button-prev{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #a6a5a5a3;
    border: none;
    color: #fff;
    font-weight: bold;
    transition: .5s;
}
.carousel .swiper-button-prev::after,  .carousel .swiper-button-next::after{
    font-size:20px !important;
}
.carousel .swiper-button-next:hover, .carousel .swiper-button-prev:hover{
    background-color: #fff;
    color: #000;
}
.carousel .swiper-pagination .swiper-pagination-bullet-active::after {
    content: "";
    background: #a7a7a7;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .carousel .swiper-pagination .swiper-pagination-bullet-active {
    border: 1px solid #fff;
    background-image: none !important;
    width: 20px !important;
    height: 20px !important;
    padding: 0;
    position: relative;
    background-color: #0000 !important;
    outline: unset !important;
  }
  .carousel .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: #ababab5e !important;
    margin: 0 10px 0 0 !important;
    opacity: 1;
  }
  .carousel .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

/* animation */
.carousel .list .item:nth-child(1){
    z-index: 1;
}

/* animation text in first item */


.carousel .list .item:nth-child(1) .content .title,
.carousel .list .item:nth-child(1) .content .des,
.carousel .list .item:nth-child(1) .content .buttons
{
    transform: translateY(50px);
    filter: blur(20px);
    opacity: 0;
    animation: showContent .5s 1s linear 1 forwards;
}
@keyframes showContent{
    to{
        transform: translateY(0px);
        filter: blur(0px);
        opacity: 1;
    }
}
.carousel .list .item.active {
    z-index: 1; /* Ensure active slide is on top */
}

.carousel .list .item.active .content .title,
.carousel .list .item.active .content .des,
.carousel .list .item.active .content .buttons {
    transform: translateY(50px);
    filter: blur(20px);
    opacity: 0;
    animation: showContent .5s 1s linear 1 forwards;
}
.carousel .list .item.active .content .title {
    animation-delay: 1.2s !important;
}
.carousel .list .item.active .content .topic {
    animation-delay: 1.4s !important;
}
.carousel .list .item.active .content .des {
    animation-delay: 1.6s !important;
}
.carousel .list .item.active .content .buttons {
    animation-delay: 1.8s !important;
}
/* create animation when next click */
.carousel.next .list .item:nth-child(1) img{
    width: 150px;
    height: 220px;
    position: absolute;
    bottom: 50px;
    left: 50%;
    border-radius: 30px;
    animation: showImage .5s linear 1 forwards;
}
@keyframes showImage{
    to{
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}

.carousel.next .thumbnail .item:nth-last-child(1){
    overflow: hidden;
    animation: showThumbnail .5s linear 1 forwards;
}
.carousel.prev .list .item img{
    z-index: 100;
}
@keyframes showThumbnail{
    from{
        width: 0;
        opacity: 0;
    }
}
.carousel.next .thumbnail{
    animation: effectNext .5s linear 1 forwards;
}

@keyframes effectNext{
    from{
        transform: translateX(150px);
    }
}

/* prev click */

.carousel.prev .list .item:nth-child(2){
    z-index: 2;
}

.carousel.prev .list .item:nth-child(2) img{
    animation: outFrame 0.5s linear 1 forwards;
    position: absolute;
    bottom: 0;
    left: 0;
}
@keyframes outFrame{
    to{
        width: 150px;
        height: 220px;
        bottom: 50px;
        left: 50%;
        border-radius: 20px;
    }
}

.carousel.prev .thumbnail .item:nth-child(1){
    overflow: hidden;
    opacity: 0;
    animation: showThumbnail .5s linear 1 forwards;
}
.carousel.next .arrows button,
.carousel.prev .arrows button{
    pointer-events: none;
}

.carousel.prev .list .item:nth-child(2) .content .title,
.carousel.prev .list .item:nth-child(2) .content .des,
.carousel.prev .list .item:nth-child(2) .content .buttons
{
    animation: contentOut 1.5s linear 1 forwards!important;
}

@keyframes contentOut{
    to{
        transform: translateY(-150px);
        filter: blur(20px);
        opacity: 0;
    }
}
.carousel .list .item.active img {
    transform: scale(1.05);
    
}


.carousel .list .item:not(.active) img {
    transform: scale(1);
  
}


.carousel .list .item img {
    transition: transform 2s ease;
}
.banner-section-custom .title{
    font-size: 45px;
}
.banner-section-custom .des{
    font-size: 16px;
}
.banner-section-custom button{
    font-size: 13px;
}

/* @media (max-width:899px){
    .carousel .list .item .content{
            top: 15%;
            padding-right:0;
        }
} */
@media (max-width:800px){
    .banner-section-custom .title{
        font-size: 32px;
    }
    .banner-section-custom .des{
        font-size: 14px;
    }
   
    
}
@media screen and (max-width: 678px) {
    
    .carousel .list .item .content{
        
        left: 10%;
        top:50%;
        transform: translate(-5%, -50%);
       
    }
    .carousel .swiper-button-next, .carousel .swiper-button-prev{
        display:none;
    }
}